/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import styles from './style.module.css';
import { FSFHorizontalLogo } from 'assets/icons';

const Header = ({ isLocationValid }) => {
  const isMobile = useContext(MobileContext);

  return (
    <>
      <div className={isMobile ? styles.mobileWrapper : styles.wrapper}>
        {isLocationValid ? (
          <a href="https://www.fitnesssf.com/" target="_blank" rel="noopener noreferrer">
            <img
              src={FSFHorizontalLogo}
              alt="logo"
              className={isMobile ? styles.mobileLogo : styles.logo}
            />
          </a>
        ) : (
          <a href="https://www.fitnesssf.com/" target="_blank" rel="noopener noreferrer">
            <img
              src={FSFHorizontalLogo}
              alt="logo"
              className={isMobile ? styles.mobileLogo : styles.logo}
            />
          </a>
        )}
      </div>
    </>
  );
};

Header.propTypes = {
  isHideMenu: PropTypes.bool.isRequired,
};

export default Header;
